import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import {createLogger} from 'redux-logger';
import {autoRehydrate} from 'redux-persist';
import rootReducer from './root-reducer';
import ReduxPersist from '@iso/config/redux_persist.config';
import rootSaga from './root-saga';
import RehydrationServices from './rehydration-service';

const sagaMiddleware = createSagaMiddleware();
const middlewares: any[] = [];
const enhancers: any[] = [];

if (true || process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
        predicate: (getState, action) => action.type !== 'COLLAPSE_OPEN_DRAWER'
    });
    middlewares.push(logger);
}

middlewares.push(thunk);
middlewares.push(sagaMiddleware);

const bindMiddleware = middleware => {
    if (true || process.env.NODE_ENV !== 'production') {
        const {composeWithDevTools} = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

if (ReduxPersist.active) {
    enhancers.push(autoRehydrate());
}

enhancers.push(bindMiddleware(middlewares))


const store = createStore(rootReducer, compose(...enhancers));

if (ReduxPersist.active) {
    RehydrationServices.updateReducers(store);
}
// @ts-ignore
sagaMiddleware.run(rootSaga);
export {store};
