import {IBucket, IHotel} from "@iso/redux/mail/profile";

export enum Buckets {
    Inbox = "Inbox",
    Sent = "Sent",
    Trash = "Trash"

}

export enum Hotels {
    // ALL = "All Hotels",
    HOTEL_CAZA_SAN_FRANCISCO = "Hotel Caza San Francisco",
    JAMES_SOHO = "James Soho",
    ROYALTON_NEW_YORK = "Royalton New York",
    ROYALTON_PARK_AVENUE = "Royalton Park Avenue"
}

export enum ActionTags {
    UNASSIGNED = "Unassigned",
    CREATE_RESERV = "Creating Reservation",
    MODIFY_RESERV = "Modify Reservation",
    CANCEL_RESERV = "Cancel Reservation",
    CONFIRM_RESERV = "Confirm Reservation",
    ANS_QUESTION = "Answer questions",
    HOTEL_COMM = "Hotel Communication",
    OTHER = "Other"
}

export interface ITag {
    tag: string,
    color: string,
    tagName: string,
}

export interface INewTag {
    id: string,
    color: string,
    displayName: string,
}

export interface IContact {
    name: string,
    email: string,
}

export enum OutlookColor {
    preset0 = "#ff0000",
    preset1 = "#ffa500",
    preset2 = "#654321",
    preset3 = "#ffff00",
    preset4 = "#00ff00",
    preset5 = "#008080",
    preset6 = "#808000",
    preset7 = "#0000ff",
    preset8 = "#800080",
    preset9 = "#cb4C78",
    preset10 = "#43464b",
    preset11 = "#1d2739",
    preset12 = "#808080",
    preset13 = "#a9a9a9",
    preset14 = "#000000",
    preset15 = "#8b0000",
    preset16 = "#ff8c00",
    preset17 = "#654321",
    preset18 = "#9b870c",
    preset19 = "#006400",
    preset20 = "#008080",
    preset21 = "#556b2f",
    preset22 = "#00008b",
    preset23 = "#301934",
    preset24 = "#3d2942",
}

export enum ActionTagsToNum {
    UNASSIGNED,
    CREATE_RESERV,
    MODIFY_RESERV,
    CANCEL_RESERV,
    CONFIRM_RESERV,
    ANS_QUESTION,
    HOTEL_COMM,
    OTHER,
}

export enum ActionColor {
    UNASSIGNED = "#9a9999",
    CREATE_RESERV = "#24cd1e",
    MODIFY_RESERV = "#1e96cd",
    CANCEL_RESERV = "#cd1e78",
    CONFIRM_RESERV = "#1e50cd",
    ANS_QUESTION = "#cd871e",
    HOTEL_COMM = "#cdca1e",
    OTHER = "rgba(112,30,205,0.88)",
}

export enum TaskState {
    DONE = "Task Processed",
    IN_PROGRESS = "Task In progress",
    DEFERRED = "Task deferred",
    CANCELLED = "Archive Mail",
}

export interface IMoveREST {
    old: IRESTRequestSingle;
    new: IRESTRequestSingle;
}

export interface ILockREST extends ILock {
    id?: number;
    mailId: number;
    hotelId: number;
    folderId: number;
}

export interface IContactREST extends IContact {
    userId?: number;
}

export interface ISearchContactsREST {
    userId?: number;
    query: string;
}

export interface ITaskREST extends ILock {
    id?: number;
    mailId: number;
    hotelId: number;
    folderId: number;
    createdBy: string;
    taskState: string;
    lastUpdate: Date;

}

export interface IMailDraftREST {
    id?:number;
    hotelId: number;
    folderId: number;
    mailId: number;
    to: string;
    cc: string;
    bcc: string;
    body?: string;
    subject?: string;
    replyAction: ReplyActions
}

export interface IRESTRequestAll {
    hotelId: number;
    folderId: number;
}

export interface IRESTRequestSingle {
    hotelId: number;
    folderId: number;
    mailId: number;
}

export interface IRESTApiResponse {
    [key: number]: {
        lock?: ILock,
        task?: { taskState: string },
        draft?: IReplyMail
    }
}


export interface ILock {    
    lockedById: string | null;
    lockedByName: string | null;
    lockedOn: Date | null;
    lockType: TaskState | null;
    isPaused: boolean;
    reasonToPause?: string;
    commentToPause?: string;
    timeBeforePauseInSeconds: number;
}

export enum ReplyActions {
    Reply = 'Reply',
    ReplyAll = 'ReplyAll',
    Forward = 'Forward',
    Send = 'Send'
}

export interface IReplyMailTymeGlobal {
    message: {
        body: { "contentType": "Text" | "Html", "content": string },
        subject?: string,
        torecipients: Array<{
            emailAddress: {
                name: string,
                address: string
            }
        }>
        ccrecipients: Array<{
            emailAddress: {
                name: string,
                address: string
            }
        }>
        bccrecipients: Array<{
            emailAddress: {
                name: string,
                address: string
            }
        }>
        attachments: Array<{
            name: string,
            contentType: string,
            contentBytes : string                   
        }>     
    },
    time: number    
}

export interface IReplyMail {
    parent: IMail,
    to: string,
    cc: string,
    bcc: string,
    subject?: string,
    body: string,
    replyAction: ReplyActions,
    attachments?: Array<IAttachments>
}

export interface IMail {
    id: number;
    name: string;
    cc: string;
    bcc: string;
    to: string;
    email: string;
    body: string;
    subject: string;
    date: Date;
    bucket: IBucket;
    read: boolean;
    important: boolean;
    hotel: IHotel;
    tags: string[];
    task?: ITaskREST;
    taskState?: TaskState;
    replyAction?: ReplyActions;     
    lock?: ILock;
    mailDraft?: IReplyMail;
    hasAttachment: boolean;
    htmlBody: string;
    attachments: Array<IAttachments>;
    attachedFiles?: any[]
}

export interface IMailFilter {
    bucket: IBucket;
    hotel: IHotel;
    name?: string;
    email?: string;
    tags?: ActionTags[],
    taskState?: TaskState
    sort: 'ASC' | 'DESC' | 'UPDATE',
    dateFrom?: Date,
    dateTo?: Date,
    subject?: string;
    text?: string;
    defaultBucket: IBucket,
    lastReceivedMailId?: number,
    changedlastReceivedMailId?:boolean
}

export interface IMailDelete {
    folder: number,
    mailId: number,
    hotel: number
}

export interface IMailEditCategory {
    category: string,
    mailId: number
}

export interface IMailMoveFolder {
    oldFolder: number,
    newFolder: number,
    mailId: number,
    hotel: number
}

export interface IMailEditCategory {
    category: string,
    mailId: number
}

export interface IAttachments {
    name: string,
    contentType: string,
    contentBytes : string 
}

export interface IAttachmentsMail {
    mailId: number,
    attachments: any[] 
}
