import * as R from "ramda";
import {IBucket, IHotel, IUserProfile} from "@iso/redux/mail/profile";
import {IMail, IMailFilter, IReplyMail, IMailMoveFolder, IMailDelete, IMailEditCategory} from "@iso/redux/mail/interfaces";

const mailActions = {
    CHECK_LAST_MAIL: 'CHECK_LAST_MAIL',
    CHANGE_LAST_MAIL: 'CHANGE_LAST_MAIL',
    FILTER_ACTION: 'FILTER_ACTION',
    SELECTED_MAIL: 'SELECTED_MAIL',
    ON_MAIL_SELECTION_COMPLETE:'ON_MAIL_SELECTION_COMPLETE',
    COMPOSE_MAIL: 'COMPOSE_MAIL',
    REPLY_MAIL: 'REPLY_MAIL',
    REPLYALL_MAIL: 'REPLYALL_MAIL',
    FORWARD_MAIL: 'FORWARD_MAIL',
    REPLY_MAIL_ACTION: 'REPLY_MAIL_ACTION',
    SEARCH_STRING: 'SEARCH_STRING',
    UPDATE_MAIL: 'UPDATE_MAIL',
    LOAD_MORE:'LOAD_MORE',

    SET_HOTELS: 'SET_HOTELS',
    SET_BUCKETS: 'SET_BUCKETS',
    STARTUP_COMPLETE:'STARTUP_COMPLETE',
    SET_MAILS: 'SET_MAILS',
    UPDATE_LOCK: 'UPDATE_LOCK',
    UPDATE_TASK: 'UPDATE_TASK',
    SEND_MAIL:'SEND_MAIL',
    MOVE_MAIL:'MOVE_MAIL',
    DELETE_MAIL:'DELETE_MAIL',
    UPDATE_BUCKETS: 'UPDATE_BUCKETS',
    EDIT_CATEGORY: 'EDIT_CATEGORY',

    setMails: (allMails: IMail[]) => ({
        type: mailActions.SET_MAILS,
        allMails,
    }),
    loadMore: () => ({
        type: mailActions.LOAD_MORE,
    }),
    sendMail: (replyMail:IReplyMail)=> ({
        type: mailActions.SEND_MAIL,
        replyMail,
    }),
    moveMail: (mailMoveFolder:IMailMoveFolder)=> ({
        type: mailActions.MOVE_MAIL,
        mailMoveFolder
    }),
    deleteMail: (mailDelete:IMailDelete)=> ({
        type: mailActions.DELETE_MAIL,
        mailDelete
    }),
    editCategory: (mailEditCategory:IMailEditCategory)=> ({
        type: mailActions.EDIT_CATEGORY,
        mailEditCategory
    }),
    setHotels: (profile: IUserProfile) => ({
        type: mailActions.SET_HOTELS,
        allHotels: profile?.report_group?.hotels,
    }),
    setBuckets: (profile: IUserProfile) => {
        const buck: IBucket[] = profile?.report_group?.hotels?.length > 0 ? profile.report_group.hotels[0].accounts[0].folders : [];
        
        let filterAttr: IMailFilter | null = null;
        if (buck.length > 0) {
            const defaultFolderIdx = R.findIndex(R.propEq('name', 'Inbox'))(buck) || 0
            filterAttr = {
                bucket: buck[defaultFolderIdx],
                defaultBucket: buck[defaultFolderIdx],
                hotel: profile.report_group.hotels[0],
                sort: 'DESC'
            };

        }

        return (dispatch, getState) => {
            dispatch({
                type: mailActions.SET_BUCKETS,
                buckets: buck,
            });
            dispatch({
                type: mailActions.FILTER_ACTION,
                filterAttr,
            });
        };
    },
    filterAction: (newFilterAttr: { [key: string]: any }) => {
        return (dispatch, getState) => {
            const filterAttr = getState().Mails.filterAttr;
            const userProfile: IUserProfile = getState().profile.userProfile;
            console.log('actions filterAction',newFilterAttr);
            // @ts-ignore
            const newFilter: IMailFilter = R.mergeRight(filterAttr, newFilterAttr);
            newFilter.changedlastReceivedMailId= filterAttr.changedlastReceivedMailId ? filterAttr.changedlastReceivedMailId : undefined
            
            // @ts-ignore
            if(newFilter.hotel === undefined)
            {
                return;
            }
            if (newFilter.defaultBucket && newFilter.bucket.id !== filterAttr.bucket.id && newFilter.bucket.id === filterAttr.defaultBucket.id) {
                console.log('filterAction default bucket selected');
                newFilter.changedlastReceivedMailId=false
            } 
            if (newFilter.hotel.id !== filterAttr.hotel.id) {
                console.log('filterAction hotel changed');
                newFilter.changedlastReceivedMailId=false
            } 

            // @ts-ignore
            const hotel: IHotel = R.find(R.propEq('id', newFilter.hotel.id))(userProfile.report_group.hotels)
            dispatch({
                type: mailActions.FILTER_ACTION,
                filterAttr: newFilter,
                lastMailId : newFilterAttr.lastMailId || 0,
                buckets: hotel.accounts[0]?.folders || []
            });

        };
    },
    checkLastMail: (action: string)=> {
        return (dispatch, getState) => {
            const filterAttr = getState().Mails.filterAttr;
            // @ts-ignore
            const actualMailId = filterAttr.lastReceivedMailId
            //const newFilter: IMailFilter = R.mergeRight(filterAttr, newFilterAttr);
            // console.log('actions checkLastMail newFilterAttr' , newFilterAttr);
            console.log('actions checkLastMail ' + action , filterAttr);
            switch(action) {
                case "NEW HOTEL":
                    filterAttr.lastReceivedMailId=null
                    filterAttr.changedlastReceivedMailId=false
                    break;
                case "SELECT DEFAULT":             
                    filterAttr.lastReceivedMailId=null
                    filterAttr.changedlastReceivedMailId=false
            }
            dispatch({
                type: mailActions.CHECK_LAST_MAIL,
                filterAttr: filterAttr
            });
        };
    },
    changeLastMail: (filterAttr: IMailFilter) => ({
        type: mailActions.CHANGE_LAST_MAIL,
        filterAttr,
    }),
    selectMail: selectedMail => {
        return (dispatch, getState) => {
            const allMails = getState().Mails.allMails;
            allMails[
                allMails.findIndex(mail => mail.id === selectedMail)
                ].read = true;
            dispatch({
                type: mailActions.SELECTED_MAIL,
                selectedMail,
                allMails,
            });
        };
    },
    onMailSelectionComplete: (selectedMail:IMail) => {
        return (dispatch, getState) => {
            const allMails = getState().Mails.allMails;
            const selectedMailIndex =  allMails.findIndex(mail => mail.id === selectedMail.id);
            selectedMail.read = true;
            allMails[selectedMailIndex] = selectedMail;
            dispatch({
                type: mailActions.ON_MAIL_SELECTION_COMPLETE,
                selectedMail:selectedMail.id,
                allMails,
            });
        };
    },
    changeComposeMail: composeMail => ({
        type: mailActions.COMPOSE_MAIL,
        composeMail,
    }),
    changeReplyMail: replyMail => ({
        type: mailActions.REPLY_MAIL,
        replyMail,
    }),
    changeReplyAction: replyMailAction => ({
        type: mailActions.REPLY_MAIL_ACTION,
        replyMailAction,
    }),
    changeSearchString: searchString => ({
        type: mailActions.SEARCH_STRING,
        searchString,
    }),
    updateLock: (updateLockObject: { index: number, update: { [key: string]: any } }) => {
        return (dispatch, getState) => {
            dispatch({
                type: mailActions.UPDATE_LOCK,
                updateLockObject
            });
        };
    },
    updateTask: (updateTaskObject: { index: number, update: { [key: string]: any } }) => {
        return (dispatch, getState) => {
            dispatch({
                type: mailActions.UPDATE_TASK,
                updateTaskObject,
            });
        };
    },
    updateMail: (updateMailObject: { index: number, update: { [key: string]: any } }) => {
        return (dispatch, getState) => {
            console.log('updateMailObject is : ', updateMailObject)
            const allMails = getState().Mails.allMails;
            const mailIndex = allMails.findIndex(m => m.id === updateMailObject.update.id);
            const mail = allMails[mailIndex] = updateMailObject.update;
            const mailDraft = updateMailObject.update.mailDraft;
            //var mailId = (mail as IMail).id
            //const buk = (updateMailObject.update as IMail).hotel.accounts[0].folders
            dispatch({
                type: mailActions.UPDATE_MAIL,
                allMails,
                mailDraft
                //selectedMail:updateMailObject.update
            });
        };
    },
    updateBuckets:(updateMailObject: { index: number, update: { [key: string]: any }, bucket: IBucket}) => {
        return (dispatch, getState) => {
            console.log('updateMailObject is : ', updateMailObject)
            const allMails = getState().Mails.allMails;
            const mailIndex = allMails.findIndex(m => m.id === updateMailObject.update.id);
            const mail = allMails[mailIndex] = updateMailObject.update;
            var mailId = (mail as IMail).id;
            allMails.splice(mailIndex, 1);
            const buck = mail.hotel.accounts[0].folders;
            const defaultFolderIdx = R.findIndex(R.propEq('name', 'Inbox'))(buck) || 0
            const folder = updateMailObject.bucket
            const prevFilterAttr = getState().Mails.filterAttr;
            let newFilterAttr: IMailFilter | null = null;
            newFilterAttr = {
                bucket: folder,
                defaultBucket: buck[defaultFolderIdx],
                hotel: mail.hotel,
                sort: 'DESC'
            }
            dispatch({
                type: mailActions.FILTER_ACTION,
                filterAttr: newFilterAttr,
                buckets: buck,
                hotel: mail.hotel,
                text: "salir"
            });
        };
    },
    updateBucketsMoveMail: (moveMail: IMailMoveFolder) => {
        return (dispatch, getState) => {
            console.log('updateBucketsMoveMail is : ', moveMail)
            const allMails = getState().Mails.allMails;
            const mailIndex = allMails.findIndex(m => m.id === moveMail.mailId);
            const mail = allMails[mailIndex];
            var mailId = (mail as IMail).id;

            allMails.splice(mailIndex, 1);
            const buck = getState().Mails.buckets;
            const oldBucket=R.findIndex(R.propEq('id', moveMail.oldFolder))(buck);
            if (oldBucket) buck[oldBucket].email_count -=1;
            const newBucket=R.findIndex(R.propEq('id', moveMail.newFolder))(buck);
            if (newBucket) buck[newBucket].email_count +=1;
            
            const defaultFolderIdx = R.findIndex(R.propEq('name', 'Inbox'))(buck) || 0
            const folder = (mail.hotel.accounts[0].folders)
            const prevFilterAttr = getState().Mails.filterAttr;
            let newFilterAttr: IMailFilter | null = null;

        };
    },

};
export default mailActions;
