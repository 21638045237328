import {all, delay, fork, put} from 'redux-saga/effects';
import volatileActions from '../volatile/actions'

const tag = '[STARTUP]';

function* waitForStartupSequenceToFinish() {

  yield delay(2000) // DO NOT REMOVE
  console.log(`${tag} startup sequence complete..`);
}


export function* startup() {
  try {
    yield waitForStartupSequenceToFinish();

    yield put(volatileActions.startupComplete());

  } catch (e) {
    console.warn(`${tag} Failed to startup : ${e.message}`);
  }


}


export default function* rootSaga() {
  yield all([
    fork(startup)
  ]);
}
