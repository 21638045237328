import {notification} from 'antd';

const createNotification = (type: "success" | "error" | "info" | "warn" | "warning" | "open" | "close" | "config" , message: string, description?: string) => {
  // @ts-ignore
  notification[type]({
    message,
    description,
  });
};
export default createNotification;
