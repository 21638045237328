import actions from './actions';

const initState = {
    startupComplete: false,
    loginComplete:false,
};

export default function appReducer(state = initState, action) {
    switch (action.type) {
        case actions.STARTUP_COMPLETE:
            return {
                ...state,
                startupComplete: true,
            };
        case actions.LOGIN_COMPLETE:
            return {
                ...state,
              loginComplete: true,
            };
        default:
            return state;
    }
}
