/**
 * Validates an apisauce response object for okkami api
 * Returns response data obj if ok. otherwise throw error
 * @param responseObj
 * @returns {boolean|*}
 */
import {ApiResponse} from "apisauce"

export function validateOrThrowApiResponse(responseObj?: ApiResponse<JSON>): any {
    const isInvalid =
        !responseObj ||
        (responseObj.status && (responseObj.status < 200 || responseObj.status > 299)) ||
        !responseObj.ok ||
        (responseObj.data && (responseObj.data as any).error)
    console.log('[TYME_API] response code : ', responseObj?.status , responseObj);
    if (isInvalid && responseObj) {
        let errorMessage = responseObj.problem
        if (responseObj.data && (responseObj.data as any).error) {
            errorMessage = (responseObj.data as any).error
        }
        throw Error(errorMessage + '')
    } else if (!responseObj) {
        throw Error("response is undefined")
    }
    console.log('[TYME_API] response  : ', {status: responseObj?.status, data: responseObj?.data});
    return responseObj && responseObj.data
}

export function convertUTCDateToLocalDate(date: Date): Date {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
}
