import * as localForage from 'localforage';
import {persistStore} from 'redux-persist';
import ReduxPersist from '@iso/config/redux_persist.config';
import appActions from "@iso/redux/app/actions";

const tag = '[REHYDRATION_SERVICE]';
const updateReducers = (store) => {
    const reducerVersion = ReduxPersist.reducerVersion;
    const config = ReduxPersist.storeConfig;
    const startup = () => store.dispatch(appActions.startup());

    localForage.getItem('reducerVersion').then((localVersion) => {
        console.log(`${tag} stored reducerVersion : ${localVersion}`)
        if (localVersion !== reducerVersion) {
            // Purge store
            console.warn(`${tag} Local version ${localVersion} did NOT match reducer version ${reducerVersion} , attempting to purge`);
            persistStore(store, config, startup).purge();
            console.log(`${tag} Setting reducer version to : ${reducerVersion}`);
            localForage.setItem('reducerVersion', reducerVersion);
        } else {
            console.log(`${tag} Starting to persist store using version : ${reducerVersion}`);
            persistStore(store, config, startup);
        }
    }).catch((e) => {
        console.error(`${tag} Failed to get reducer version : ${e.message}`);
        console.log(`${tag} Starting to persist store using version : ${reducerVersion}`);
        persistStore(store, config, startup);

        localForage.setItem('reducerVersion', reducerVersion);
    });

};

export default {updateReducers};
