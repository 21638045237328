import {contacts} from './reducer';
import {IContactREST, ISearchContactsREST, IContact} from "@iso/redux/mail/interfaces";

function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : '~';
  const name2 = contact2.name ? contact2.name.toUpperCase() : '~';
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const contactActions = {
  ADD_CONTACT: 'ADD_CONTACT',
  STORE_CONTACT: 'STORE_CONTACT',
  EDIT_CONTACT: 'EDIT_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',
  CHANGE_CONTACT: 'CHANGE_CONTACT',
  SEARCH_CONTACT: 'SEARCH_CONTACT',
  SET_CONTACTS: 'SET_CONTACTS',
  EDIT_VIEW: 'EDIT_VIEW',
  changeContact: id => ({
    type: contactActions.CHANGE_CONTACT,
    id,
  }),
  addContact: () => {
    const newContact = {
      id: new Date(),
      firstName: '',
      // @ts-ignore
      avatar: contacts[new Date() % 10].avatar,
      LastName: '',
      mobile: '',
      home: '',
      name: '',
      company: '',
      work: '',
      note: '',
    };
    return (dispatch, getState) => {
      dispatch({
        type: contactActions.ADD_CONTACT,
        contacts: [...getState().Contacts.contacts, newContact],
        selectedId: newContact.id,
      });
    };
  },
  editContact: newContact => {
    return (dispatch, getState) => {
      const contacts = getState().Contacts.contacts;
      const newContacts = [];
      contacts.forEach(contact => {
        if (contact.id === newContact.id) {
          // @ts-ignore
          newContacts.push(newContact);
        } else {
          // @ts-ignore
          newContacts.push(contact);
        }
      });
      dispatch({
        type: contactActions.EDIT_CONTACT,
        contacts: newContacts.sort(ascendingSort),
      });
    };
  },
  deleteContact: id => {
    return (dispatch, getState) => {
      const contacts = getState().Contacts.contacts;
      const selectedId = getState().Contacts.selectedId;
      const newContacts = [];
      contacts.forEach(contact => {
        if (contact.id === id) {
        } else {
          // @ts-ignore
          newContacts.push(contact);
        }
      });
      dispatch({
        type: contactActions.DELETE_CONTACT,
        contacts: newContacts,
        selectedId: id === selectedId ? undefined : selectedId,
      });
    };
  },
  searchContact:  (contact: ISearchContactsREST) => {
      return (dispatch, getState) => {
        dispatch({
          type: contactActions.SEARCH_CONTACT,
          contact,
        });
    }
  },
  setContacts:  (contacts: IContact) => ({
    type: contactActions.SET_CONTACTS,
    contacts,
  }),
  viewChange: view => ({
    type: contactActions.EDIT_VIEW,
    view,
  }),
};
export default contactActions;
