import {all} from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import contactSagas from '@iso/redux/contacts/saga';
import mailSagas from '@iso/redux/mail/saga';
import profileSaga from '@iso/redux/profile/saga';
import appSaga from '@iso/redux/app/saga'

export default function* rootSaga(getState: any) {
    yield all([
        appSaga(),
        authSagas(),
        contactSagas(),
        mailSagas(),
        profileSaga(),
    ]);
}
