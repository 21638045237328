import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import {PUBLIC_ROUTE} from './route.constants';
import Loader from '@iso/components/utility/loader';
import usePromise from "react-promise-suspense/build";

const Dashboard = lazy(() => import('@iso/containers/Dashboard/Dashboard'));

const tag = '[ROUTER] '

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.AUTHORIZE,
        exact: false,
        component: lazy(() => import('@iso/containers/Pages/Authorize/Authorize')),
    },
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.PAGE_404,
        component: lazy(() => import('@iso/containers/Pages/404/404')),
    },
    {
        path: PUBLIC_ROUTE.PAGE_500,
        component: lazy(() => import('@iso/containers/Pages/500/500')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
    }
];

function PrivateRoute({children, ...rest}) {
    const isLoggedIn = useSelector((state: any) => state.Auth.idToken);
    console.log(tag + 'isLoggedIn ? ', isLoggedIn)
    return (
        <Route
            {...rest}
            render={({location}) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/dashboard',
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}



export default function Routes() {
    // @ts-ignore
    const {startupComplete} = useSelector(state => state.volatile, shallowEqual);
    console.log(tag + ' startupComplete = ' + startupComplete);
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader/>}>
                {startupComplete ?  <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}
                                   render={(props) => {
                                       // @ts-ignore
                                       return <route.component {...props} />
                                   }}/>

                        ))}
                        <PrivateRoute path="/dashboard">
                            <Dashboard/>
                        </PrivateRoute>
                    </Switch>
                </Router> : <Loader/> }
            </Suspense>
        </ErrorBoundary>
    );
}
