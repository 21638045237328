const localOAuthSettings = process.env.NODE_ENV !== 'production' ? {
    uid: 'VaDUN1GOuZSq6V3879-SxUn-J-NPKKZXsYIRmskYU3s',
    secret: 'La-OtOW0Q-rcCJ3QoMBq2W9sCgJFGH9bmh83-cH_qIg',
    scope: 'email',
    callback: encodeURI('https://localhost:3000/authorize')
} : {}
const localSettings = process.env.NODE_ENV !== 'production' ? {
    uiApi: 'http://localhost:3001/',
} : {}
export default {
    siteName: 'Tyme Global',
    siteIcon: 'ion-flash',
    footerText: `Tyme Global @ ${new Date().getFullYear()} Created by Khan v1.0.0.1`,
    enableAnimatedRoute: false,
    apiUrl: 'http://yoursite.com/api/',
    google: {
        analyticsKey: 'UA-xxxxxxxxx-1',
    },
    dashboard: '/dashboard',
    tymeApi: 'https://timeclock.tymeglobal.com/',
    uiApi: 'http://email.tymeglobal.com:3001/',
    oauth: {
        uid: 'uh9EePGe3QVndjkY3IWsPZ2Nald5UJ2eORPUhbyt-yY',
        secret: '1fSyjyRGY0ZElXfSg9JwBUmzSp347VlulIYt3Kxn9OM',
        scope: 'email',
        callback: encodeURI('https://email.tymeglobal.com/authorize'),
        ...localOAuthSettings
    },
    ...localSettings
};
