import apisauce from 'apisauce';
import config from '@iso/config/site.config'
import {IMailFilter, IReplyMailTymeGlobal} from "@iso/redux/mail/interfaces";
import axios from 'axios';

const create = (baseURL = config.tymeApi) => {
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {},
        // 10 second timeout...
        timeout: 60000
    });

    // Force OpenWeather API Key on all requests
    // tslint:disable-next-line
    api.addRequestTransform((request) => {

    });

    const getHeader = (token) => ({headers: {Authorization: `Bearer ${token}`,}})

    const getAccessToken = (token) => api.post(`oauth/token?grant_type=authorization_code&code=${token}&client_id=${config.oauth.uid}&client_secret=${config.oauth.secret}&redirect_uri=${config.oauth.callback}`, {});
    const getProfile = (token) => api.get(`api/v2/me`, undefined,
        getHeader(token)
    )

    const getMails = (token, folderId: number, page = 0, filterAttr: IMailFilter) => {
        const subjectFilter = (filterAttr.subject == null || filterAttr.subject == "") ? "" : "&subject=" + filterAttr.subject
        const bodyFilter = (filterAttr.text == null || filterAttr.text == "") ? "" : "&body=" + filterAttr.text
        const mailFilter = (filterAttr.email == null || filterAttr.email == "") ? "" : "&email=" + filterAttr.email
        const dateFromFilter = (filterAttr.dateFrom == null) ? "" : "&dateFrom=" + filterAttr.dateFrom
        const dateToFilter = (filterAttr.dateTo == null) ? "" : "&dateTo=" + filterAttr.dateTo
        const category = (filterAttr.tags && filterAttr.tags[0]) ?  "&category=" + encodeURIComponent(filterAttr.tags[0]) : ""
        const sortBy = "&sort_by=" + (filterAttr.sort === 'DESC' ? encodeURIComponent('-date') : encodeURIComponent('+date'))
        const url = `api/v3/mails?folder=${folderId}&startFrom=${page}&pageSize=30` + sortBy + bodyFilter + subjectFilter + mailFilter + dateFromFilter + category + dateToFilter
        console.log('getMails to ', url, ' with data : ', filterAttr)
        return api.get(url, undefined, getHeader(token))
    }
    const getLastMail = (token, folderId: number) => {
        const sortBy = "&sort_by=" + encodeURIComponent('-date')
        const url = `api/v3/mails?folder=${folderId}&startFrom=0&pageSize=1` + sortBy
        return api.get(url, undefined, getHeader(token))
    }

    const sendMail = (token, accountId: number, message: IReplyMailTymeGlobal) => {
        const url = `api/v2/accounts/${accountId}/sendmail`
        const data = {
            subject: message.message.subject,
            message: message.message.body.content,
            toRecipients: message.message.torecipients,
            ccRecipients: message.message.ccrecipients,
            bccRecipients: message.message.bccrecipients,
            time: 120,
            attachments: message.message.attachments
        }; // `message=${JSON.stringify(message.message)}&time=${Math.round(message.time)}`
        console.log('sendMail to ', url, ' with data : ', data)
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)
        return api.post(url, data, header)
    }

    const replyMail = (token, mailId: number, message: IReplyMailTymeGlobal) => {
        const url = `api/v2/mails/${mailId}/reply`
        const data = {
            reply: message.message.body.content,
            toRecipients: message.message.torecipients,
            ccRecipients: message.message.ccrecipients,
            bccRecipients: message.message.bccrecipients,
            time: Math.round(message.time),
            attachments: message.message.attachments
        }; // `message=${JSON.stringify(message.message)}&time=${Math.round(message.time)}`
        console.log('replyMail to ', url, ' with data : ', data)
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)
        return api.post(url, data, header)
    }
    const replyAllMail = (token, mailId: number, message: IReplyMailTymeGlobal) => {
        const url = `api/v2/mails/${mailId}/replyall`
        const data = {
            reply: message.message.body.content,
            toRecipients: message.message.torecipients,
            ccRecipients: message.message.ccrecipients,
            bccRecipients: message.message.bccrecipients,
            time: Math.round(message.time),
            attachments: message.message.attachments
        };
        console.log('replyAllMail to ', url, ' with data : ', data)
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)
        return api.post(url, data, header)
    }
    const forwardMail = (token, mailId: number, message: IReplyMailTymeGlobal) => {
        const url = `api/v2/mails/${mailId}/forward`
        const data = {
            comment: message.message.body.content,
            toRecipients: message.message.torecipients,
            ccRecipients: message.message.ccrecipients,
            bccRecipients: message.message.bccrecipients,
            time: Math.round(message.time),
            attachments: message.message.attachments
        };
        console.log('forwardMail to ', url, ' with data : ', data)
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)
        return api.post(url, data, header)
    }
    const moveMail = (token, mailId: number, folderId: number) => {
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)

        const url = `api/v2/move/${mailId}`
        const data = `{"folder":${folderId}}`
        console.log('moveMail to ', url, ' with data : ', data)
        const response = api.post(url, data, header)
        console.log('response ', response)
        return response
    }
    const deleteMail = (token, mailId: number) => {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                time: 10
            }
        }
        console.log('Header : ', header)
        const url = config.tymeApi + `api/v2/mails/${mailId}`
        console.log('deleteMail to ', url, ' with data : ', header)
        const response = axios.delete(url, header);
        console.log('response ', response)
        return response
    }

    const editCategory = (token, mailId: number, displayName: string, time: number) => {
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)

        const url = `api/v2/mail/${mailId}/category`
        const data = `{"displayName":"${displayName}","time":5}`
        console.log('editCategory to ', url, ' with data : ', data)
        const response = api.patch(url, data, header)
        console.log('response ', response)
        return response
    }

    const getAttachments = (token, mailId: number) => {
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)
        const url = `api/v2/mails/${mailId}/attachments`
        console.log('URL attachments: ', url)
        return api.get(url, undefined, getHeader(token))
    }

    const getAttach = (token, url: string) => {
        const header = getHeader(token);
        header.headers['content-type'] = 'application/json'
        console.log('Header : ', header)
        console.log('URL attach: ', url)
        return api.get(url, undefined, getHeader(token))
    }

    return {
        axios: api.axiosInstance,
        getAccessToken,
        sendMail,
        getProfile,
        getMails,
        getLastMail,
        replyMail,
        replyAllMail,
        forwardMail,
        moveMail,
        deleteMail,
        editCategory,
        getAttachments,
        getAttach,
    };
};

// let's return back our create method as the default.
export default create();
