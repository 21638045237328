// saga.js
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import profileActions from './actions';
import loginActions from '../auth/actions'
import tymeApi from "../../api/tymeApi";
import {getIdToken} from "@iso/redux/auth/saga";
import {validateOrThrowApiResponse} from "../../api/utilities";
import {IBucket, IHotel, IUserProfile} from "@iso/redux/mail/profile";
import authActions from "@iso/redux/auth/actions";
import * as R from "ramda";


export const getUserProfile = (state): IUserProfile | undefined => state.profile.userProfile
export const getAccountId = (state): number | undefined => getUserProfile(state)?.id

function* fetchProfileDataEffect() {
    const tag = '[FETCH_PROFILE_DATA_START] '
    try {
        const token = yield select(getIdToken)
        console.log('Token is :', token)
        if (token) {
            const apiResponse = yield call(tymeApi.getProfile, token)
            const parsedObject = validateOrThrowApiResponse(apiResponse)
            const result: IUserProfile = parsedObject.employee
            //const result:IUserProfile = validateOrThrowApiResponse(apiResponse);
            console.log(tag + 'Result : ', result)

            const h: IHotel[] = result.report_group.hotels;

            for (let i = 0; i < h.length; ++i) {
                let buck: IBucket[] = h[i]?.accounts[0]?.folders || [];
                buck = R.filter((b: IBucket) => !!b.name, buck);
                R.sort((a: IBucket, b: IBucket) => {
                    // @ts-ignore
                    return a.ordinal - b.ordinal
                }, buck);

                if (buck.length > 0)
                    result.report_group.hotels[i].accounts[0].folders = buck;
            }


            yield put(profileActions.fetchProfileDataSuccess(result));
        } else {
            throw Error('Missing token')
        }
    } catch (e) {
        console.warn(tag + 'Failed with error : ' + e.message);
        yield put(profileActions.fetchProfileDataFailure(e.message));
        /*if(e.message === 'CLIENT_ERROR'){
            yield put(authActions.logoutRequest())
        }*/
    }
}

export default function* profileSaga() {
    yield all([
        takeEvery(profileActions.FETCH_PROFILE_DATA_START, fetchProfileDataEffect),
        takeEvery(loginActions.LOGIN_SUCCESS, fetchProfileDataEffect),
    ]);
}
