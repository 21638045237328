import actions from './actions';
import {IMail, ReplyActions} from "@iso/redux/mail/interfaces";

const allMails: IMail[] = []; // require('./data');
const allHotels = []; //Hotels;

const initState = {
    allMails,
    allHotels,
    isMailFiltering:false,
    tag: undefined,
    selectedMail: -1,
    filterAttr: null,
    composeMail: false,
    replyMail: false,
    searchString: '',
    mailDraft: null
};


export default function mailReducer(state = initState, action) {
    switch (action.type) {
        case actions.STARTUP_COMPLETE:
            return {
                ...state,
                isMailFiltering:false,
                composeMail: false,
            };
        case actions.SET_BUCKETS:
            return {
                ...state,
                buckets:action.buckets
            }
        case actions.SET_HOTELS:
            return {
                ...state,
                allHotels:action.allHotels
            }
        case actions.SET_MAILS:
            return {
                ...state,
                isMailFiltering:false,
                allMails: action.allMails,
            }
        case actions.UPDATE_MAIL:
            return {
                ...state,
                allMails: action.allMails,
                mailDraft:action.mailDraft
            };

        case actions.FILTER_ACTION:
            // @ts-ignore
            const buckets = action.buckets || state.buckets ;
            return {
                ...state,
                buckets,
                isMailFiltering:true,
                // composeMail: false,
                // replyMail: false,
                // selectedMail: -1,
                filterAttr: {...action.filterAttr},
                // buckets: action.buckets
            };
        case actions.CHECK_LAST_MAIL:
                // @ts-ignore
                console.log('CHECK_LAST_MAIL', action)
                return {
                    ...state,
                   
                    filterAttr: {...action.filterAttr},
                    // buckets: action.buckets
                };
        case actions.CHANGE_LAST_MAIL:
            // @ts-ignore
            console.log('CHANGE_LAST_MAIL', action)
            return {
                ...state,
                
                filterAttr: {...action.filterAttr},
                // buckets: action.buckets
            };
        case actions.ON_MAIL_SELECTION_COMPLETE:
            return {
                ...state,
                replyMail: false,
                selectedMail: action.selectedMail,
                allMails: action.allMails,
            };
        case actions.COMPOSE_MAIL:
            return {
                ...state,
                replyMail: false,
                composeMail: action.composeMail,
            };
        case actions.REPLY_MAIL:
            return {
                ...state,
                replyMail: action.replyMail,
                replyMailAction: ReplyActions.Reply,
            };
        case actions.REPLYALL_MAIL:
        return {
            ...state,
            replyMail: action.replyMail,
            replyMailAction: ReplyActions.ReplyAll,
        };
        case actions.FORWARD_MAIL:
        return {
            ...state,
            replyMail: action.replyMail,
            replyMailAction: ReplyActions.Forward,
        };
        case actions.REPLY_MAIL_ACTION:
            return {
                ...state,
                replyMail: true,
                replyMailAction: action.replyMailAction
            };
        case actions.SEARCH_STRING:
            return {
                ...state,
                searchString: action.searchString,
            };
        case actions.UPDATE_BUCKETS:
            return {
                ...state,
                buckets:action.buckets
            }
        default:
            return state;
    }
}
