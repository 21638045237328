import apisauce from 'apisauce';
import config from '@iso/config/site.config'
import {ILock, ILockREST, IRESTRequestAll, IRESTRequestSingle, ITaskREST, IMoveREST, IMailDraftREST, IContactREST, ISearchContactsREST} from "@iso/redux/mail/interfaces";

const create = (baseURL = config.uiApi) => {
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {},
        // 10 second timeout...
        timeout: 60000
    });

    // Force OpenWeather API Key on all requests
    // tslint:disable-next-line
    api.addRequestTransform((request) => {

    });


    const updateLock = (lock: ILockREST | undefined) => {
        console.log('[updateLock] calling updateLock with : ', lock)
        const updateResponse = api.post(`updateLock`, lock)
        return updateResponse
    };

    const updateTaskState = (taskState: ITaskREST | undefined) => {
        console.log('[updateTaskState] calling updateTaskState with : ', taskState)
        return api.post(`updateTaskState`, taskState)
    };

    const updateContacts = (contacts: IContactREST[]  | undefined) => {
        console.log('[contacts] calling contacts with : ', contacts)
        return api.post(`contacts`, contacts)
    };
    
    const seachContacts = (search: ISearchContactsREST  | undefined) => {
        console.log('[searchContacts] calling search contacts with : ', search)
        return api.get(`contacts`, search)
    };
    
    const updateMailDraft = (mailDraft: IMailDraftREST | undefined) => {
        console.log('[updateMailDraft] calling updateMailDraft with : ', mailDraft)
        return api.post(`updateMailDraft`, mailDraft)
    };

    const updateMoveMail = (moveMail: IMoveREST | undefined) => {
        console.log('[updateMoveMail] calling updateMoveMail with : ', moveMail)
        return api.post(`updateMoveMail`, moveMail)
    };

    const getLockStates = (reqAllParam: IRESTRequestAll | undefined) => {
        console.log('[getLockStates] calling getLockStates with : ', reqAllParam)
        return api.post(`getLockStates`, reqAllParam)
    };

    const getLockState = (reqAllParam: IRESTRequestSingle | undefined) => {
        console.log('[getLockState] calling getLockState with : ', reqAllParam)
        return api.post(`getLockState`, reqAllParam)
    };
    
    return {
        axios: api.axiosInstance,
        updateLock,
        updateContacts,
        seachContacts,
        updateMailDraft,
        updateTaskState,
        updateMoveMail,
        getLockState,
        getLockStates
    };
};

// let's return back our create method as the default.
export default create();
