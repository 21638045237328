import React from 'react';
import {ConfigProvider} from 'antd';
// @ts-ignore
import {IntlProvider} from 'react-intl';
import {useSelector} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';

// @ts-ignore
export default function AppProvider({children}) {
    const {locale} = useSelector((state:any) => (state as any).LanguageSwitcher.language);
    const {themeName} = useSelector((state:any) => (state as any).ThemeSwitcher.changeThemes);
    const currentAppLocale = (AppLocale as any)[locale];
    return (
        <ConfigProvider locale={currentAppLocale.antd}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <ThemeProvider theme={(themes as any)[themeName]}>{children}</ThemeProvider>
            </IntlProvider>
        </ConfigProvider>
    );
}
