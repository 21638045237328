import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {createBrowserHistory} from 'history';
import tymeApi from "../../api/tymeApi";
import actions from './actions';
import volatileActions from '../volatile/actions'
import {validateOrThrowApiResponse} from "../../api/utilities";

const history = createBrowserHistory();

export const getIdToken = (state) => state.Auth.idToken?.access_token || state.Auth.idToken

export function* loginRequest() {
    // @ts-ignore
    yield takeEvery('LOGIN_REQUEST', function* ({payload}) {
        const tag = '[LOGIN_REQUEST] '
        const {token} = payload;
        try {
            if (token) {

                const apiResponse = yield call(tymeApi.getAccessToken, token)
                const result = validateOrThrowApiResponse(apiResponse);
                console.log(tag + 'Result : ', result)

                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token: result,
                });
                window.location.replace('/dashboard');
            } else {
                throw Error('Missing token')
            }
        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            //yield put({type: actions.LOGIN_ERROR});
        }
    });
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        // @ts-ignore
        yield localStorage.setItem('id_token', payload.token);
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {
    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {   
        window.location.replace('https://timeclock.tymeglobal.com/tymeclock');                     
    });
}


function* doCheckAuthorization() {
    const token = yield select(getIdToken);
    if (token) {
        yield put({
            type: actions.LOGIN_SUCCESS,
            token,
            profile: 'Profile',
        });
    }
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, doCheckAuthorization);
    yield takeEvery(volatileActions.STARTUP_COMPLETE, doCheckAuthorization);
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout),
    ]);
}
