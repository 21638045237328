import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {IContact} from "@iso/redux/mail/interfaces";
import {validateOrThrowApiResponse} from "../../api/utilities";
import actions from './actions';
import uiApi from "../../api/uiApi"

export function* addContact() {
  yield takeEvery(actions.ADD_CONTACT, function*() {});
}
export function* editContact() {
  yield takeEvery(actions.EDIT_CONTACT, function*() {});
}
export function* deleteContact() {
  yield takeEvery(actions.DELETE_CONTACT, function*() {});
}
export function* storeContact(contacts: IContact[]) {
  console.log('storeContact: ', contacts)
  const apiResponse1 = yield call(uiApi.updateContacts, contacts)
  console.log('storeContact: ', apiResponse1)
}
export function* searchContact() {
  yield takeEvery(actions.SEARCH_CONTACT, function*(action) {
  try {
    // @ts-ignore
    console.log('searchContact: ', action.contact)
    // @ts-ignore
    const apiResponse1 = yield call(uiApi.seachContacts, action.contact)
    const parsedObject = validateOrThrowApiResponse(apiResponse1)
    console.log('searchContact result: ', parsedObject)
    // @ts-ignore
    yield put(actions.setContacts(parsedObject));


    //return parsedObject
  } catch (e) {
    console.warn('searchContact Failed with error : ' + e.message);
    //return null
}
})
}

export default function* rootSaga() {
  yield all([fork(addContact), fork(searchContact), fork(editContact), fork(deleteContact)]);
}
