import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import mailActions from './actions';
import profileActions from "@iso/redux/profile/actions";
import {IBucket, IUserProfile} from "@iso/redux/mail/profile";
import {getUserProfile} from "@iso/redux/profile/saga";
import {storeContact} from "@iso/redux/contacts/saga";
import notification from '@iso/components/Notification';
import {
    ILockREST,
    IMailDraftREST,
    IMail,
    IMailEditCategory,
    IMailFilter,
    IMailMoveFolder,
    IMailDelete,
    IMoveREST,
    IReplyMail,
    IReplyMailTymeGlobal,
    IRESTApiResponse,
    IRESTRequestSingle,
    ITaskREST,
    IContactREST,
    ISearchContactsREST,
    ReplyActions,
    TaskState
} from "@iso/redux/mail/interfaces";
import {getIdToken} from "@iso/redux/auth/saga";
import tymeApi from "../../api/tymeApi";
import uiApi from "../../api/uiApi"
import {validateOrThrowApiResponse} from "../../api/utilities";
import {IReceivedMail} from "@iso/redux/mail/mail";
import * as R from 'ramda';
import {trim} from 'ramda';
import { profile } from 'console';

let lastBucket: IBucket | null = null;
let lastMailId: number = 0;
let lastIndex: number = 0;
let restartLoad: number = 0;
const getMailDraft = (state) => state.Mails.mailDraft
const getFilters = (state) => state.Mails.filterAttr
const getMailById = (state, id) => R.find(R.propEq('id', id))(state.Mails.allMails)          
const getMailIndexById = (state, id) => R.findIndex(R.propEq('id', id))(state.Mails.allMails)

export function* loadMore() {
    yield takeEvery(actions.LOAD_MORE, function* () {
        const filters = yield select(getFilters);
        const allMails: IMail[] = yield select(s => s.Mails.allMails) || [];
        const lastMailId = allMails[allMails.length - 1].id;
        //console.warn("Loadmore is disabled ..")

        // @ts-ignore
        yield put(mailActions.filterAction({...filters, ...{lastMailId: lastMailId}}));
    })
}


export function* filterAction() {
    yield takeEvery(actions.FILTER_ACTION, function* (action) {
        // @ts-ignore
        if (action.filterAttr === null) {
            return;
        }
        const tag = '[FILTER_ACTION] '
        // @ts-ignore
        const filterAttr: IMailFilter = action.filterAttr;
        // @ts-ignore
        console.log(tag + 'filterAttr = ', {filterAttr, lastMailId: action.lastMailId});


        // @ts-ignore
        lastMailId = action.lastMailId;

        let page = 0;
        let mailIndex = -1;
        const allMails: IMail[] = yield select(s => s.Mails.allMails) || [];
        if (lastMailId) {
            mailIndex = allMails.findIndex(m => m.id === lastMailId);
            page = (mailIndex + 1) / 30;
        }

        if (page < 1) {
            page = 0;
            mailIndex = -1;
        }

        /*if(lastIndex > 0 && mailIndex === -1)
        {
            restartLoad = 1;
        }*/

        lastIndex = mailIndex;

        lastBucket = filterAttr.bucket;

        const bucket = filterAttr.bucket;
        const hotel = filterAttr.hotel;

        try {
            const token = yield select(getIdToken)
            if (token && bucket) {

                const resultBuckets: IUserProfile =yield select(getUserProfile);
                const currentHotel = resultBuckets.report_group.hotels.find(x => x.id === hotel.id);

                if (currentHotel !== undefined) {
                    hotel.accounts[0].folders.map(bucket => {
                        const newEmailCount = currentHotel?.accounts[0].folders.find(buk => buk.id === bucket.id)?.email_count;
                        bucket.email_count = newEmailCount || bucket.email_count;
                    })
                }

                // @ts-ignore
                const apiResponse1 = yield call(tymeApi.getMails, token, bucket.id, page || 0, filterAttr)
                console.log('ApiResponse1: ', apiResponse1)
                const parsedObject = validateOrThrowApiResponse(apiResponse1)
                const tymeApiResponse: IReceivedMail[] = parsedObject.emails
                const apiResponse2 = yield call(uiApi.getLockStates, {
                    hotelId: hotel.id,
                    folderId: bucket.id
                });
                console.log(tag + ' ApiResponse2 : ', apiResponse2)
                //Comenté las proximas 2 lineas
                const lockTaskState: IRESTApiResponse = validateOrThrowApiResponse(apiResponse2)
                console.log(tag + ' Mails are : ', {tymeApiResponse, lockTaskState})
                let finalResult: IMail[] = [];
                var contacts: IContactREST[] = [];

                // @ts-ignore
                if (action.lastMailId) {
                    finalResult = yield select(s => s.Mails.allMails);
                }
                let mails: IMail[] = tymeApiResponse.reduce((result: IMail[], m: IReceivedMail) => {
                    try {
                        const mail = m.content;

                        //Comenté la proxima linea y todo el if
                        const lock = lockTaskState[m.id]?.lock;
                        if (lock) {
                            console.log(tag + ' lock.lockedOn = ', lock.lockedOn);
                            // @ts-ignore
                            lock.lockedOn = new Date(lock.lockedOn)
                            console.log(tag + ' lock.lockedOn = ', lock.lockedOn);
                        }
                        const md = lockTaskState[m.id]?.draft;

                        const mx = {
                            id: m.id || '',
                            name: mail.from?.emailAddress.name || '',
                            cc: mail.ccRecipients?.map(v => v.emailAddress.address).join(', '),
                            bcc: mail.bccRecipients?.map(v => v.emailAddress.address).join(', '),
                            email: mail.from?.emailAddress.address || '',
                            to: mail.toRecipients?.map(v => v.emailAddress.address).join(', '),
                            body: mail.body?.content || '',
                            subject: mail.subject || '',
                            date: new Date(mail.sentDateTime) || new Date(2020, 1, 1),
                            bucket: bucket || '',
                            read: false,
                            tags: mail.categories || [],
                            important: mail.flag?.flagStatus !== 'notFlagged' || '',
                            hotel: hotel,
                            lock: lock,
                            task: lockTaskState[m.id]?.task,
                            mailDraft: md || null,
                            taskState: lockTaskState[m.id]?.task?.taskState,
                            hasAttachment: mail.hasAttachments,
                            htmlBody: mail.htmlBody || "<div><p> </p><p> </p></div>"
                        } as IMail

                        if (!filterAttr.taskState || filterAttr.taskState === mx.task?.taskState) {
                            result.push(mx);

                            const contact = {
                                userId: resultBuckets.id,
                                name: mail.from?.emailAddress.name || '',
                                email: mail.from?.emailAddress.address || '',
                            } as IContactREST
    
    
                            if (contact.email)
                                contacts.push(contact);
                            
                            const recipients = [...mail.toRecipients,...mail.ccRecipients, ...mail.bccRecipients]    
                            recipients?.map(v => 
                                {const c = {
                                    userId: resultBuckets.id,
                                    name: v.emailAddress.name || '',
                                    email: v.emailAddress.address || '',
                                } as IContactREST
                                if (c.email)
                                    contacts.push(c);
                            })
    
                        }
                        

                    } catch (e) {
                        console.warn(tag + 'Failed to map mail : ' + e.message, m);
                    }
                    return result;

                }, finalResult);

                console.log(tag + ' Formatted mail : ', mails)
                console.log(tag + ' Formatted Contacts : ', contacts)
                yield fork(storeContact, contacts);

                yield put(mailActions.setMails(mails));

            } else {
                throw Error('Missing token')
            }
        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            // yield put(mailActions.setMails([]));
            //if (e.message === 'CLIENT_ERROR') {
            //    yield put(authActions.logoutRequest())
            //}
        }


    });
}

export function* checkLastMail() {
    yield takeEvery(actions.CHECK_LAST_MAIL, function* (action) {
        // @ts-ignore
        if (action.filterAttr === null) {
            return;
        }
        const tag = '[CHECK_LAST_MAIL] '
        // @ts-ignore
        const filterAttr= yield select(getFilters)
        // @ts-ignore
        console.log(tag + 'filterAttr = ',filterAttr.defaultBucket,filterAttr.lastReceivedMailId);

        const bucketId = filterAttr.defaultBucket.id || 0;

        try {
            const token = yield select(getIdToken)
            if (token && bucketId) {

                const apiResponse1 = yield call(tymeApi.getLastMail, token, bucketId)
                console.log('ApiResponse1: ', apiResponse1)
                const parsedObject = validateOrThrowApiResponse(apiResponse1)
                const tymeApiResponse: IReceivedMail[] = parsedObject.emails

                console.log(tag + ' New ReceivedMail : ', tymeApiResponse)
                console.log(tag + ' New ReceivedMail [0] : ', tymeApiResponse[0])
                console.log(tag + ' New ReceivedMail ID : ', tymeApiResponse[0].id)
                const mailId = tymeApiResponse[0].id

                if (filterAttr.lastReceivedMailId && filterAttr.lastReceivedMailId!==mailId) {
                    filterAttr.changedlastReceivedMailId=true
                }
                filterAttr.lastReceivedMailId=mailId
                if(filterAttr.changedlastReceivedMailId)
                    yield put(mailActions.changeLastMail(filterAttr));

            } else {
                throw Error('Missing token')
            }
        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            // yield put(mailActions.setMails([]));
            //if (e.message === 'CLIENT_ERROR') {
            //    yield put(authActions.logoutRequest())
            //}
        }


    });
}

export function* setMailMetaData() {
    yield takeEvery(profileActions.FETCH_PROFILE_DATA_SUCCESS, function* () {
        const profile: IUserProfile = yield select(getUserProfile)
        // @ts-ignore
        yield put(actions.setBuckets(profile))
        yield put(actions.setHotels(profile))
    })
}


export function* updateLock() {
    yield takeEvery(actions.UPDATE_LOCK, function* (action) {
        const tag = '[UPDATE_LOCK] '
        // @ts-ignore
        const lockObject: { index: number, update: IMail } = action.updateLockObject;

        console.log(tag + ' lockObject ', lockObject)
        const md = yield select(getMailDraft);
        console.log(tag + ' draft ', md)


        try {
            const filter: IMailFilter = yield select(getFilters);
            const profile: IUserProfile = yield select(getUserProfile);

            // save draft mail
            const mailDraft= (md) ? {
                hotelId: lockObject.update.hotel.id,
                folderId: lockObject.update.bucket.id,
                mailId: lockObject.update.id,
                to: md.to,
                cc: md.cc,
                bcc: md.bcc,
                body: md.body,
                subject: md.subject || lockObject.update.subject,
                replyAction: md.replyAction
            } as IMailDraftREST: undefined

            console.log(tag + ' mailDraft ', mailDraft)
            // @ts-ignore
            const lock = lockObject.update.lock ? {
                reasonToPause: lockObject.update.lock.reasonToPause,
                commentToPause: lockObject.update.lock.commentToPause,
                isPaused: lockObject.update.lock.isPaused,
                timeBeforePauseInSeconds: lockObject.update.lock.timeBeforePauseInSeconds,
                lockedOn: new Date(),
                folderId: filter.bucket.id,
                hotelId: filter.hotel.id,
                lockedById: profile.id,
                lockedByName: profile.first_name + " " + profile.last_name,
                lockType: lockObject.update.lock.lockType || lockObject.update.taskState || '',
                mailId: lockObject.update.id,
            } as ILockREST : undefined

            if (lockObject.update.taskState === TaskState.DEFERRED)  {
                console.log(tag + ' Calling to update mailDraft :', mailDraft);
                const apiResponse1 = yield call(uiApi.updateMailDraft, mailDraft)
                const result1: IReceivedMail[] = validateOrThrowApiResponse(apiResponse1);
                console.log(tag + ' mailDraft is : ', result1)
            }

            console.log(tag + ' Calling to update lock :', lock);
            const apiResponse = yield call(uiApi.updateLock, lock)
            const result: IReceivedMail[] = validateOrThrowApiResponse(apiResponse);
            console.log(tag + ' lock is : ', result)
            

            // @ts-ignore
            lockObject.update.lock = result;
            // @ts-ignore
            yield put(mailActions.updateMail(lockObject))

        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);

        }
    });
}

export function* updateTask() {
    yield takeEvery(actions.UPDATE_TASK, function* (action) {
        const tag = '[UPDATE_TASK] '
        // @ts-ignore
        const taskObject: { index: number, update: IMail } = action.updateTaskObject;

        console.log(tag + ' taskObject ', taskObject)

        try {
            const filter: IMailFilter = yield select(getFilters);
            const profile: IUserProfile = yield select(getUserProfile);
            // @ts-ignore
            const task = taskObject ? {
                folderId: filter.bucket.id,
                hotelId: filter.hotel.id,
                createdBy: profile.first_name + " " + profile.last_name,
                lockedById: profile.id,
                lockedByName: profile.first_name + " " + profile.last_name,
                taskState: taskObject.update.taskState || '',
                lockType: taskObject.update.taskState || '',
                mailId: taskObject.update.id,
            } as ITaskREST : undefined
            console.log(tag + ' Calling to update task :', task);
            const apiResponse1 = yield call(uiApi.updateTaskState, task)
            const taskResult: IReceivedMail[] = validateOrThrowApiResponse(apiResponse1);
            const apiResponse2 = yield call(uiApi.updateLock, task)
            const lockResult: IRESTApiResponse = validateOrThrowApiResponse(apiResponse2);
            console.log(tag + ' task is : ', {taskResult, lockResult})

            // @ts-ignore
            taskObject.update.task = taskResult;
            taskObject.update.lock = undefined;
            // @ts-ignore
            yield put(mailActions.updateMail(taskObject));

        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);

        }
    });
}

export function* selectMail() {
    yield takeEvery(actions.SELECTED_MAIL, function* (action) {
        const tag = '[SELECTED_MAIL] '
        // @ts-ignore
        const taskObject: { selectedMail: number, allMails: IMail[] } = {
            // @ts-ignore
            selectedMail: action.selectedMail,
            // @ts-ignore
            allMails: action.allMails
        };

        const selectedMail = R.find(R.propEq('id', taskObject.selectedMail))(taskObject.allMails);

        console.log(tag + ' taskObject ', taskObject)

        try {
            const filter: IMailFilter = yield select(getFilters);

            // @ts-ignore
            const task: IRESTRequestSingle = taskObject ? {
                folderId: filter.bucket.id,
                hotelId: filter.hotel.id,
                mailId: taskObject.selectedMail
            } : undefined;
            console.log(tag + ' Calling to get lock and task :', task);
            const apiResponse = yield call(uiApi.getLockState, task)
            const lockState: IRESTApiResponse = validateOrThrowApiResponse(apiResponse);

            let toMerge = {
                lock: lockState[taskObject.selectedMail].lock,
                //mailDraft: lockState[taskObject.selectedMail].draft,
                taskState: lockState[taskObject.selectedMail].task?.taskState,
                attachedFiles: []
            };

            console.log(tag + ' lockState is : ', lockState)

            const hasAttachment = taskObject.allMails?.find(mail => mail.id === taskObject.selectedMail)?.hasAttachment;
            if(hasAttachment)
            {
                const token = yield select(getIdToken)
                const apiResponseAttach = yield call(tymeApi.getAttachments, token, taskObject.selectedMail)
                console.log('mailId: ', taskObject.selectedMail)
                console.log('ApiResponseAttach: ', apiResponseAttach)
                const attachmentList = validateOrThrowApiResponse(apiResponseAttach)
                toMerge.attachedFiles = attachmentList
            }

            const updatedMail = {...selectedMail, ...toMerge}
            console.log('Calling mailActions.onMailSelectionComplete with : ', updatedMail)
            // @ts-ignore
            yield put(mailActions.onMailSelectionComplete(updatedMail))


        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            // @ts-ignore
            yield put(mailActions.onMailSelectionComplete(selectedMail))

        }
    });
}

export function* moveMail() {
    yield takeEvery(actions.MOVE_MAIL, function* (action) {
        const tag = '[MOVE_MAIL] '
        // @ts-ignore
        const moveFolder: IMailMoveFolder = action.mailMoveFolder
        const newFolder = moveFolder.newFolder
        const oldFolder = moveFolder.oldFolder
        const hotel = moveFolder.hotel
        const id = moveFolder.mailId

        try {
            const token = yield select(getIdToken)
            const apiResponse = yield call(tymeApi.moveMail, token, id, newFolder);
            const moveResponse: IRESTApiResponse = validateOrThrowApiResponse(apiResponse);
            // @ts-ignore
            const newMailId = moveResponse.email.id;
            // @ts-ignore
            const newFolderId = moveResponse.email.folder_id;
            console.log(tag + "Move mail response is : ", moveResponse);
            notification('success', `Mail Moved Successfully`, '');

            const oldMove: IRESTRequestSingle = {
                hotelId: hotel,
                folderId: oldFolder,
                mailId: id
            }
            const newMove: IRESTRequestSingle = {
                hotelId: hotel,
                folderId: newFolderId,
                mailId: newMailId
            }
            const moveMail = {
                old: oldMove,
                new: newMove
            } as IMoveREST

            const apiResponse1 = yield call(uiApi.updateMoveMail, moveMail);
            if (apiResponse1.problem !== "SERVER_ERROR") {
                const result: IReceivedMail[] = validateOrThrowApiResponse(apiResponse1);
                console.log(tag + ' move mail is : ', result)
            }
            
            // @ts-ignore
            yield put(actions.updateBucketsMoveMail(moveFolder));           

        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            // yield put(mailActions.updateBucketsMoveMail(moveFolder));
            notification('error', `Failed to move email`, '')
        }
    });
}

export function* deleteMail() {
    yield takeEvery(actions.DELETE_MAIL, function* (action) {
        const tag = '[DELETE_MAIL] '
        // @ts-ignore
        const deleteMail: IMailDelete = action.mailDelete
        const folder = deleteMail.folder
        const hotel = deleteMail.hotel
        const id = deleteMail.mailId

        try {
            const token = yield select(getIdToken)
            const apiResponse = yield call(tymeApi.deleteMail, token, id);
            console.log(tag + "Delete mail response is : ", apiResponse);
            notification('success', `Mail Deleted`, '');

            /*const toDelete: IRESTRequestSingle = {
                hotelId: hotel,
                folderId: oldFolder,
                mailId: id
            }

            const deleteMail = {
                toDelete: toDelete,
            } as IDeleteREST

            const apiResponse1 = yield call(uiApi.deleteMail, deleteMail);
            if (apiResponse1.problem !== "SERVER_ERROR") {
                const result: IReceivedMail[] = validateOrThrowApiResponse(apiResponse1);
                console.log(tag + ' delete mail is : ', result)
            }*/

        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            notification('error', `Failed to delete mail`, '')
        }
    });
}

export function* editCategory() {
    yield takeEvery(actions.EDIT_CATEGORY, function* (action) {
        const tag = '[EDIT_CATEGORY] '
        // @ts-ignore
        const editCategory: IMailEditCategory = action.mailEditCategory
        const category = editCategory.category
        const id = editCategory.mailId
        const now = new Date();
        const time = (now.getTime())

        try {
            const token = yield select(getIdToken)
            const apiResponse = yield call(tymeApi.editCategory, token, id, category, time)
            const editCategoryResponse: IRESTApiResponse = validateOrThrowApiResponse(apiResponse);
            console.log(tag + "Edit category response is : ", editCategoryResponse);
            notification('success', `Mail Category Edited Successfully`, '')

        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            notification('error', `Failed to edit email category`, '')
        }
    });
}

export function* sendMail() {
    yield takeEvery(actions.SEND_MAIL, function* (action) {
        const tag = '[SEND_MAIL] '
        // @ts-ignore
        const replyMail: IReplyMail = action.replyMail;
        const profile: IUserProfile = yield select(getUserProfile);

        console.log(tag + ' replyMail ', replyMail)

        try {
            const parent = replyMail.replyAction === ReplyActions.Send ? null : yield select(getMailById, replyMail.parent.id);
            console.log('Parent is : ', parent);
            const now = new Date();

            let time = (now.getTime())
            if (typeof parent?.lock !== "undefined") {
                const lockStarted = new Date(parent.lock!.lockedOn!);//  cuz ths is utc

                console.log('Lock diff : ', {now, lockStarted});

                time = (now.getTime() - lockStarted.getTime()) / 1000 + parent!.lock!.timeBeforePauseInSeconds || 0;
            }

            const message: IReplyMailTymeGlobal = {
                message: {
                    body: {
                        contentType: "Html",
                        content: replyMail.body,
                    },
                    subject: replyMail.subject,
                    torecipients: replyMail.to !== '' && replyMail.to?.split(",").map(address => {
                        return {
                            emailAddress: {
                                name: trim(address),
                                address: trim(address)
                            }
                        }
                    }) || [],
                    ccrecipients: replyMail.cc !== '' && replyMail.cc?.split(",").map(address => {
                        return {
                            emailAddress: {
                                name: trim(address),
                                address: trim(address)
                            }
                        }
                    }) || [],
                    bccrecipients: replyMail.bcc !== '' && replyMail.bcc?.split(",").map(address => {
                        return {
                            emailAddress: {
                                name: trim(address),
                                address: trim(address)
                            }
                        }
                    }) || [],
                    attachments: replyMail.attachments?.map(file => {
                        return {
                            name: file.name,
                            contentType: file.contentType,
                            contentBytes: file.contentBytes
                        }
                    }) || [],
                },
                time: time
            }
            const token = yield select(getIdToken)
            const filter: IMailFilter = yield select(getFilters)
            console.log(tag + ' Calling send mail with  :', message);
            let apiResponse;

            console.log('replyMail is: ', replyMail);
        
            const recipients = [...message.message.torecipients,...message.message.ccrecipients, ...message.message.bccrecipients]
            var contacts: IContactREST[] = [];
            recipients.map(v => 
                {const c = {
                    userId: profile.id,
                    name: v.emailAddress.name || '',
                    email: v.emailAddress.address || '',
                } as IContactREST
                if (c.email)
                    contacts.push(c);
            })

            console.log('contacts are: ', contacts);
            yield fork(storeContact, contacts);

            if (replyMail.replyAction === ReplyActions.Reply) {
                apiResponse = yield call(tymeApi.replyMail, token, parent.id, message);
            } else if (replyMail.replyAction === ReplyActions.ReplyAll) {
                apiResponse = yield call(tymeApi.replyAllMail, token, parent.id, message);
            } else if (replyMail.replyAction === ReplyActions.Forward) {
                apiResponse = yield call(tymeApi.forwardMail, token, parent.id, message);
            } else if (replyMail.replyAction === ReplyActions.Send) {
                apiResponse = yield call(tymeApi.sendMail, token, filter?.hotel?.accounts[0]?.id, message);
            }

            const replyResponse: IRESTApiResponse = validateOrThrowApiResponse(apiResponse);
            console.log(tag + "Reply response is : ", replyResponse);

            if (ReplyActions.Send !== replyMail.replyAction) {
                parent.taskState = TaskState.DONE;
                parent.lock = undefined;

                const index = yield select(getMailIndexById, parent.id)

                // @ts-ignore
                yield put(mailActions.updateTask({index: index, update: parent}))
                yield put(mailActions.changeReplyMail(false));

            }
            notification('success', `Mail has been sent`, '')

            if(replyMail.replyAction === ReplyActions.Send) { // return to main screen
                yield put(mailActions.changeComposeMail(false));
            }


        } catch (e) {
            console.warn(tag + 'Failed with error : ' + e.message);
            notification('error', `Failed to send email`, '')

        }
    });
}

export default function* rootSaga() {
    yield all([fork(filterAction),
        fork(checkLastMail),
        fork(setMailMetaData),
        fork(updateLock),
        fork(updateTask),
        fork(selectMail),
        fork(sendMail),
        fork(moveMail),
        fork(deleteMail),
        fork(loadMore),
        fork(editCategory)]);
}
