import {combineReducers} from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Mails from '@iso/redux/mail/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import Box from '@iso/redux/box/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import volatile from '@iso/redux/volatile/reducer';
export default combineReducers({
  Auth,
  App,
  LanguageSwitcher,
  ThemeSwitcher,
  Mails,
  Calendar,
  Box,
  Contacts,
  modal,
  drawer,
  profile,
  volatile,
});
